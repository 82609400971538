<template>
  <b-container>
    <ResourceList
      can-delete create-to="security.customers.create"
      resource="customer" soft-deletes title="Customers"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #buttons>
        <!-- <b-dd-item-button :disabled="currentUserCan(permissions.CAN_SEND_PASSWORD_RESET_EMAIL)">Send Password Reset Email</b-dd-item-button> -->
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-0 text-primary"><router-link class="text-dark" :to="{name: 'security.customers.update', params: { id: item.id }}">{{ item.company_name }}</router-link></p>
            <FriendlyDate class="align-self-end mb-0 text-muted" :date="item.created_at" />
          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <p class="mb-0 flex-fill text-muted">{{ item.address && item.address.address_snippet }}</p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button class="mr-1 mb-0" size="sm" :to="{name: 'security.customers.update', params: { id: item.id }}" variant="primary"><b-icon icon="person" /> View</b-button>
            <b-button class="mr-1 mb-0" :disabled="!item.contacts.length" size="sm" variant="secondary" title="Email Contacts" v-b-popover.hover.bottom="'Sends an email to all of the contacts associated with this customer.'"><b-icon icon="envelope-open" /> Email Contacts</b-button>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  created () {
    this.fetchManyCustomers(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('security-customers', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('security-customers', ['deleteManyCustomers', 'fetchManyCustomers']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteManyCustomers({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManyCustomers(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchManyCustomers({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManyCustomers({ ...this.$route.query, query })
      }
    }
  }
}
</script>

<style>

</style>
