import {
  SECURITY_CUSTOMERS_BATCH_DELETE_REQUEST, SECURITY_CUSTOMERS_BATCH_DELETE_SUCCESS, SECURITY_CUSTOMERS_BATCH_DELETE_FAILURE,
  SECURITY_CUSTOMERS_FETCH_MANY_REQUEST, SECURITY_CUSTOMERS_FETCH_MANY_SUCCESS, SECURITY_CUSTOMERS_FETCH_MANY_FAILURE,
  SECURITY_CUSTOMERS_SAVE_NOTE_REQUEST, SECURITY_CUSTOMERS_SAVE_NOTE_SUCCESS, SECURITY_CUSTOMERS_SAVE_NOTE_FAILURE,
  SECURITY_CUSTOMERS_UPLOAD_REQUEST, SECURITY_CUSTOMERS_UPLOAD_SUCCESS, SECURITY_CUSTOMERS_UPLOAD_FAILURE,
  SECURITY_CUSTOMERS_FETCH_REQUEST, SECURITY_CUSTOMERS_FETCH_SUCCESS, SECURITY_CUSTOMERS_FETCH_FAILURE,
  SECURITY_CUSTOMERS_SAVE_REQUEST, SECURITY_CUSTOMERS_SAVE_SUCCESS, SECURITY_CUSTOMERS_SAVE_FAILURE
} from './types'

export default {
  reset ({ commit }) {
    commit(SECURITY_CUSTOMERS_FETCH_SUCCESS, { data: {} })
  },
  deleteManyCustomers ({ commit }, { ids = [] }) {
    commit(SECURITY_CUSTOMERS_BATCH_DELETE_REQUEST)
    return window.axios.post('/security-customers', { _method: 'DELETE', ids }).then(response => {
      commit(SECURITY_CUSTOMERS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SECURITY_CUSTOMERS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchManyCustomers ({ commit }, { query = '', page = 1 }) {
    commit(SECURITY_CUSTOMERS_FETCH_MANY_REQUEST)
    return window.axios.get('/security-customers', { params: { query, page } }).then(response => {
      commit(SECURITY_CUSTOMERS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SECURITY_CUSTOMERS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchCustomer ({ commit }, id) {
    commit(SECURITY_CUSTOMERS_FETCH_REQUEST)
    return window.axios.get(`/security-customers/${id}`).then(response => {
      commit(SECURITY_CUSTOMERS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(SECURITY_CUSTOMERS_FETCH_FAILURE, error)
      throw error
    })
  },
  saveCustomer ({ commit }, user) {
    const path = user.id ? `/security-customers/${user.id}` : '/security-customers'
    const method = user.id ? 'put' : 'post'

    commit(SECURITY_CUSTOMERS_SAVE_REQUEST)
    return window.axios[method](path, user).then(response => {
      commit(SECURITY_CUSTOMERS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SECURITY_CUSTOMERS_SAVE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(SECURITY_CUSTOMERS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  createNote ({ commit }, { customerId, content }) {
    commit(SECURITY_CUSTOMERS_SAVE_NOTE_REQUEST)
    return window.axios.post('/security-customer-notes', { content, customer_id: customerId }).then(response => {
      commit(SECURITY_CUSTOMERS_SAVE_NOTE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SECURITY_CUSTOMERS_SAVE_NOTE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(SECURITY_CUSTOMERS_SAVE_NOTE_FAILURE, { error })
      }
      throw error
    })
  },
  uploadDocument ({ commit }, { id, data }) {
    commit(SECURITY_CUSTOMERS_UPLOAD_REQUEST)
    return window.axios.post(`/security-customers/${id}/upload`, data).then(response => {
      commit(SECURITY_CUSTOMERS_UPLOAD_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(SECURITY_CUSTOMERS_UPLOAD_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(SECURITY_CUSTOMERS_UPLOAD_FAILURE, { error })
      }
      throw error
    })
  }
}
