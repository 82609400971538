export const INVENTORY_BATCH_DELETE_REQUEST = 'INVENTORY_BATCH_DELETE_REQUEST'
export const INVENTORY_BATCH_DELETE_SUCCESS = 'INVENTORY_BATCH_DELETE_SUCCESS'
export const INVENTORY_BATCH_DELETE_FAILURE = 'INVENTORY_BATCH_DELETE_FAILURE'

export const INVENTORY_FETCH_MANY_REQUEST = 'INVENTORY_FETCH_MANY_REQUEST'
export const INVENTORY_FETCH_MANY_SUCCESS = 'INVENTORY_FETCH_MANY_SUCCESS'
export const INVENTORY_FETCH_MANY_FAILURE = 'INVENTORY_FETCH_MANY_FAILURE'

export const INVENTORY_FETCH_REQUEST = 'INVENTORY_FETCH_REQUEST'
export const INVENTORY_FETCH_SUCCESS = 'INVENTORY_FETCH_SUCCESS'
export const INVENTORY_FETCH_FAILURE = 'INVENTORY_FETCH_FAILURE'

export const INVENTORY_SAVE_REQUEST = 'INVENTORY_SAVE_REQUEST'
export const INVENTORY_SAVE_SUCCESS = 'INVENTORY_SAVE_SUCCESS'
export const INVENTORY_SAVE_FAILURE = 'INVENTORY_SAVE_FAILURE'
