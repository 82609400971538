import {
  INVENTORY_BATCH_DELETE_REQUEST,
  INVENTORY_BATCH_DELETE_SUCCESS,
  INVENTORY_BATCH_DELETE_FAILURE,
  INVENTORY_FETCH_MANY_REQUEST,
  INVENTORY_FETCH_MANY_SUCCESS,
  INVENTORY_FETCH_MANY_FAILURE,
  INVENTORY_FETCH_REQUEST,
  INVENTORY_FETCH_SUCCESS,
  INVENTORY_FETCH_FAILURE,
  INVENTORY_SAVE_REQUEST,
  INVENTORY_SAVE_SUCCESS,
  INVENTORY_SAVE_FAILURE
} from './types'

export default {
  deleteManyInventory ({ commit }, { ids = [] }) {
    commit(INVENTORY_BATCH_DELETE_REQUEST)
    return window.axios.post('/inventory', { _method: 'DELETE', ids }).then(response => {
      commit(INVENTORY_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INVENTORY_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchManyInventory ({ commit }, { query = '', page = 1 }) {
    commit(INVENTORY_FETCH_MANY_REQUEST)
    return window.axios.get('/inventory', { params: { query, page } }).then(response => {
      commit(INVENTORY_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INVENTORY_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchInventory ({ commit }, id) {
    commit(INVENTORY_FETCH_REQUEST)
    return window.axios.get(`/inventory/${id}`).then(response => {
      commit(INVENTORY_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(INVENTORY_FETCH_FAILURE, error)
      throw error
    })
  },
  saveInventory ({ commit }, payload) {
    const path = payload.id ? `/inventory/${payload.id}` : '/inventory'
    const method = payload.id ? 'put' : 'post'

    commit(INVENTORY_SAVE_REQUEST)
    return window.axios[method](path, payload).then(response => {
      commit(INVENTORY_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(INVENTORY_SAVE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(INVENTORY_SAVE_FAILURE, { error })
      }
      throw error
    })
  }
}
