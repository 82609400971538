<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Customer</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <companies-house-lookup class="mb-2" :errors="errors" v-model="lookupDetails" />
          <b-row class="mb-4">
            <b-col cols="12" md="6">
              <b-form-group label="Current Debt" :invalid-feedback="validationInvalidFeedback(errors, 'current_debt')" :state="validationState(errors, 'current_debt')">
                <b-input-group prepend="£">
                  <b-form-input :disabled="isSaving" type="number" step="0.01" v-model="current_debt" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Aged Debt" :invalid-feedback="validationInvalidFeedback(errors, 'aged_debt')" :state="validationState(errors, 'aged_debt')">
                <b-input-group prepend="£">
                  <b-form-input :disabled="isSaving" type="number" step="0.01" v-model="aged_debt" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12">
                  <h2>Registered Address</h2>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_1')" :state="validationState(errors, 'address.address_line_1')">
                    <b-form-input :disabled="isSaving" v-model="address.address_line_1" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_2')" :state="validationState(errors, 'address.address_line_2')">
                    <b-form-input :disabled="isSaving" v-model="address.address_line_2" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors, 'address.city')" :state="validationState(errors, 'address.city')">
                    <b-form-input :disabled="isSaving" v-model="address.city" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors, 'address.county')" :state="validationState(errors, 'address.county')">
                    <b-form-input :disabled="isSaving" v-model="address.county" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors, 'address.postcode')" :state="validationState(errors, 'address.postcode')">
                    <b-form-input :disabled="isSaving" v-model="address.postcode" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2>Sites</h2>
                </b-col>
                <b-col class="mb-2" cols="12" md="4">
                  <b-button block :disabled="!$route.params.id" :to="{ name: 'security.sites.create', query: { customer: $route.params.id } }" variant="secondary">Add Site</b-button>
                </b-col>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['address', 'deals']" :items="sites">
                    <template v-slot:cell(address)="{ item }">
                      {{ item.address.address_snippet }}
                    </template>
                    <template v-slot:cell(deals)="{ item }">
                      {{ item.deals && item.deals.length ? `${item.deals.length} deal${item.deals.length === 1 ? '' : 's'}` : 'No deals' }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2>Customer Notes</h2>
                </b-col>
                <b-col cols="12" md="4">
                  <b-button block :disabled="!$route.params.id" variant="primary" v-b-modal.add-customer-note v-if="currentUserCan(permissions.CAN_WRITE_SECURITY_CUSTOMER_NOTES)">Add Note</b-button>
                </b-col>
                <b-col class="mt-2" cols="12">
                  <b-card class="py-2 text-center" v-if="!currentUserCan(permissions.CAN_READ_SECURITY_CUSTOMER_NOTES)">
                    <p class="mb-2"><b-icon class="display-1 text-dark" icon="cloud-slash"></b-icon></p>
                    <p class="font-weight-normal h5 mb-0 text-dark">Can not view customer notes (you do not have permission).</p>
                  </b-card>
                  <b-table v-if="currentUserCan(permissions.CAN_READ_SECURITY_CUSTOMER_NOTES)" responsive bordered striped head-variant="dark" :fields="['content', { key: 'created_at', label: 'Created' }, 'created_by']" :items="notes">
                    <template v-slot:cell(created_at)="row">
                      <friendly-date class="mb-0" :date="row.item.created_at" />
                    </template>
                    <template v-slot:cell(created_by)="row">
                      {{ [row.item.created_by.forename, row.item.created_by.surname].join(' ') }}
                    </template>
                  </b-table>
                  <b-modal v-if="currentUserCan(permissions.CAN_WRITE_ENERGY_CUSTOMER_NOTES)" id="add-customer-note" size="lg" cancel-title="Close"
                    title="Add Customer Note" ok-variant="secondary" cancel-variant="dark"
                    @ok.prevent="createCustomerNote" @cancel="cancelCustomerNote">
                    <template v-slot:modal-ok>
                      <span v-if="!isSavingNote">Create</span><b-spinner class="mr-2" small variant="light" v-if="isSavingNote" />
                    </template>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group class="mb-0" label="Customer Note">
                          <b-form-textarea :disabled="isSavingNote" rows="6" v-model="addCustomerNote" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-modal>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="6">
                  <h2>Contacts</h2>
                </b-col>
                <b-col cols="6" md="2">
                  <b-button block :disabled="!contacts.length" :href="emailAllLink" variant="dark"><b-icon icon="envelope-open"></b-icon></b-button>
                </b-col>
                <b-col cols="12" md="4">
                  <b-button block @click.passive="editContact = {}" variant="primary" v-b-modal.edit-contact>Add Contact</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['name', 'email', 'phone', 'actions']" :items="contacts">
                    <template v-slot:cell(name)="row">
                      <p class="mb-0 text-nowrap">{{ [get(row.item, 'surname').toUpperCase(), get(row.item, 'forename')].join(', ') }}</p>
                      <p class="mb-0 text-nowrap"><em>{{ row.item.position }}</em></p>
                    </template>
                    <template v-slot:cell(phone)="row">
                      <p class="mb-0" v-if="row.item.landline"><strong>Landline:</strong> {{ row.item.landline }}</p>
                      <p class="mb-0" v-if="row.item.mobile"><strong>Mobile:</strong> {{ row.item.mobile }}</p>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2 text-nowrap">{{ row.detailsShowing ? 'Hide' : 'Show'}} Details</b-button>
                    </template>

                    <template v-slot:row-details="row">
                      <b-card>
                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Landline Phone Number:</strong></b-col>
                          <b-col>{{ row.item.landline || 'N/A' }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Mobile Phone Number:</strong></b-col>
                          <b-col>{{ row.item.mobile || 'N/A' }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Notes:</strong></b-col>
                          <b-col>{{ row.item.notes || 'N/A' }}</b-col>
                        </b-row>

                        <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2">Hide Details</b-button>
                        <b-button variant="primary" size="sm" @click="showEditContact(row.index)" class="mr-2">Edit Contact</b-button>
                        <b-button variant="danger" size="sm" @click="removeContact(row.index)" class="mr-2">Remove</b-button>
                      </b-card>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
            <b-modal id="edit-contact" size="lg" cancel-title="Close" :ok-title="editContact.index < -1 ? 'Save' : 'Create'"
              :title="editContact.index < -1 ? 'Edit Contact' : 'Create Contact'" ok-variant="secondary" cancel-variant="dark"
              @ok="addContact" @cancel="cancelContact" :ok-disabled="contactSubmitDisabled">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Forename">
                    <b-form-input v-model="editContact.forename" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Surname">
                    <b-form-input v-model="editContact.surname" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Position">
                    <b-form-input v-model="editContact.position" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Email Address">
                    <b-form-input v-model="editContact.email" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Landline Number">
                    <b-form-input v-model="editContact.landline" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Mobile Number">
                    <b-form-input v-model="editContact.mobile" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Contact Notes">
                    <b-form-textarea v-model="editContact.notes" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-modal>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2>Documents</h2>
                </b-col>
                <b-col cols="12" md="4">
                  <b-button block :disabled="!$route.params.id" variant="primary" v-b-modal.upload-document>Upload Document</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['filename', { key: 'url', label: 'URL' }, { key: 'created_at', label: 'Uploaded At' }]" :items="documents">
                    <template v-slot:cell(url)="row">
                      <a :href="downloadURL(row.item.url)">Download</a>
                    </template>
                    <template v-slot:cell(created_at)="row">
                      <friendly-date class="mb-0" :date="row.item.created_at"/>
                      <p class="mb-0"><em>{{ row.item.created_by ? `by ${[row.item.created_by.forename, row.item.created_by.surname].join(' ')}` : '' }}</em></p>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-modal id="upload-document" cancel-variant="dark" ok-variant="secondary" title="Upload Document" size="md"
               @ok.prevent="uploadCustomerDocument" @cancel="cancelUploadCustomerDocument">
                <template v-slot:modal-ok>
                  <span v-if="!isUploading">Upload</span><b-spinner class="mx-4" small variant="light" v-if="isUploading" />
                </template>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Upload Document">
                      <b-form-file v-model="uploadDocumentFile" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-modal>
            </b-col>
          </b-row>
          <b-row v-if="currentUserCan(permissions.CAN_READ_ENERGY_CUSTOMERS_HIDDEN)">
            <b-col class="mb-2" cols="12" md="4" offset-md="8">
              <b-form-checkbox v-model="hidden">Hidden</b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="4" offset-md="8">
              <b-button block class="mb-2" :disabled="isSaving" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import get from 'lodash/get'
import clone from 'lodash/clone'
import normalizeURL from 'normalize-url'
import CompaniesHouseLookup from '../../components/CompaniesHouseLookup'
import FriendlyDate from '../../components/FriendlyDate'
import currentUser from '../../mixins/currentUser'
import validation from '../../mixins/validation'
import differenceObject from '../../utils/diff'
import { mapActions, mapGetters } from 'vuex'

const initialData = () => ({
  sole_trader: false,
  company_name: null,
  company_number: null,
  aged_debt: 0,
  hidden: false,
  current_debt: 0,
  address: {
    address_line_1: '',
    address_line_2: '',
    city: '',
    county: '',
    postcode: ''
  },
  contacts: [],
  documents: [],
  notes: [],
  sites: [],
  addCustomerNote: '',
  editContact: {},
  uploadDocumentFile: null
})

export default {
  mixins: [currentUser, validation],
  created () {
    if (this.$route.params.id) {
      this.fetchCustomer(this.$route.params.id)
    }
  },
  data () {
    return initialData()
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'security.customers.create' && to.name === 'security.customers.update') {
      return next()
    }

    const differences = Object.keys(differenceObject(this.$data, this.single))
    const ignoreKeys = ['documents', 'notes', 'sites', 'addCustomerNote', 'editContact', 'uploadDocumentFile']

    if (differences.filter(d => !ignoreKeys.includes(d)).length) {
      if (!confirm('You appear to have unsaved changes. Are you sure you want to leave this page?')) {
        return
      }
    }

    this.$store.dispatch('security-customers/reset')
    Object.assign(this.$data, initialData())
    return next()
  },
  components: {
    CompaniesHouseLookup,
    FriendlyDate
  },
  computed: {
    ...mapGetters('security-customers', ['errors', 'isLoading', 'isSaving', 'isSavingNote', 'isUploading', 'single']),
    adminEmail () {
      return process.env.VUE_APP_ADMIN_EMAIL
    },
    lookupDetails: {
      get () {
        return {
          company_number: this.company_number,
          company_name: this.company_name,
          sole_trader: this.sole_trader,
          address: this.address
        }
      },
      set (value) {
        this.company_number = value.company_number
        this.company_name = value.company_name
        this.sole_trader = value.sole_trader
        this.address = value.address || {}
      }
    },
    emailAllLink () {
      const contacts = this.contacts.map(c => `${c.forename} ${c.surname} <${c.email}>`)
      return `mailto:${contacts.join(',')}?bcc=${this.adminEmail}`
    },
    contactSubmitDisabled () {
      return !this.editContact.forename || !this.editContact.surname ||
             !this.editContact.email || !(
        (this.editContact.landline && this.editContact.landline.length === 11) ||
        (this.editContact.mobile && this.editContact.mobile.length === 11)
      )
    }
  },
  methods: {
    get,
    ...mapActions('security-customers', ['fetchCustomer', 'saveCustomer', 'createNote', 'uploadDocument']),
    downloadURL (path) {
      return path[0] === '/' ? normalizeURL(process.env.VUE_APP_API_URL + path) : path
    },
    uploadCustomerDocument () {
      const formData = new FormData()
      formData.append('file', this.uploadDocumentFile, this.uploadDocumentFile.name)
      this.uploadDocument({ id: this.$route.params.id, data: formData }).then(res => {
        this.documents.unshift(res.data)
        this.uploadDocumentFile = null
        this.$bvModal.hide('upload-document')
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: 'Document uploaded successfully',
          text: 'The document has been assigned to this customer.'
        })
      }).catch(err => {
        if (err.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Error uploading document',
            text: `Error for debugging: ${err.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    },
    cancelUploadCustomerDocument () {
      this.uploadDocumentFile = null
    },
    createCustomerNote () {
      this.createNote({
        customerId: this.$route.params.id,
        content: this.addCustomerNote
      }).then(response => {
        this.notes.unshift(response.data)
        this.addCustomerNote = ''
        this.$bvModal.hide('add-customer-note')
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: 'Customer note created successfully',
          text: 'This security customer has successfully had a note added to it.'
        })
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Customer Note`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    },
    cancelCustomerNote () {
      this.addCustomerNote = ''
    },
    createDeal () {
      if (this.deals.indexOf(this.modalDeal) < 0) {
        this.deals.push(this.modalDeal)
      } else {
        this.deals.splice(this.deals.indexOf(this.modalDeal), 1, this.modalDeal)
      }
      this.cancelDeal()
    },
    cancelDeal () {
      this.modalDeal = {
        cost: 0.01,
        quantity: 1,
        inventory_id: null
      }
      this.$bvModal.hide('create-deal')
    },
    addContact () {
      if (typeof this.editContact.index === 'undefined') {
        this.contacts.push(this.editContact)
      } else {
        this.contacts.splice(this.editContact.index, 1, this.editContact)
      }

      this.editContact = {}
      this.$bvModal.hide('edit-contact')
    },
    cancelContact () {
      this.editContact = {}
    },
    removeContact (index) {
      this.contacts.splice(index, 1)
    },
    showEditContact (index) {
      this.editContact = { ...this.contacts[index], index }
      this.contacts.splice(index, 1, this.editContact)
      this.$bvModal.show('edit-contact')
    },
    submit () {
      this.saveCustomer({
        id: this.$route.params.id,
        sole_trader: this.sole_trader,
        company_name: this.company_name,
        company_number: this.company_number,
        aged_debt: this.aged_debt,
        current_debt: this.current_debt,
        hidden: this.hidden,
        address: this.address,
        contacts: this.contacts
      }).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: `Customer ${this.$route.params.id ? 'Updated' : 'Created'} Successfully`,
          text: 'This security customer has successfully been saved.'
        })

        if (!this.$route.params.id) {
          return this.$router.push({ name: 'security.customers.update', params: { id: response.data.id } })
        }

        return this.fetchCustomer(this.$route.params.id)
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Customer`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    single (value) {
      this.hidden = get(value, 'hidden')
      this.aged_debt = get(value, 'aged_debt')
      this.current_debt = get(value, 'current_debt')
      this.sole_trader = get(value, 'sole_trader')
      this.company_name = get(value, 'company_name')
      this.company_number = get(value, 'company_number')

      this.address = clone(get(value, 'address', {}))
      this.contacts = clone(get(value, 'contacts', []))
      this.documents = clone(get(value, 'files', []))
      this.notes = clone(get(value, 'notes', []))
      this.sites = clone(get(value, 'sites', []))
    }
  }
}
</script>

<style>

</style>
