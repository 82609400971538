<template>
  <router-view />
</template>

<script>
import customerModule from '../../store/security-customers'
import inventoryModule from '../../store/inventory'

export default {
  beforeCreate () {
    if (!this.$store.hasModule('inventory')) {
      this.$store.registerModule('inventory', inventoryModule)
    }
    if (!this.$store.hasModule('security-customers')) {
      this.$store.registerModule('security-customers', customerModule)
    }
  },
  destroyed () {
    if (this.$store.hasModule('inventory')) {
      this.$store.unregisterModule('inventory')
    }
    if (this.$store.hasModule('security-customers')) {
      this.$store.unregisterModule('security-customers')
    }
  }
}
</script>
