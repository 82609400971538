import pagination from '../../utils/pagination'
import {
  SECURITY_CUSTOMERS_BATCH_DELETE_REQUEST, SECURITY_CUSTOMERS_BATCH_DELETE_SUCCESS, SECURITY_CUSTOMERS_BATCH_DELETE_FAILURE,
  SECURITY_CUSTOMERS_FETCH_MANY_REQUEST, SECURITY_CUSTOMERS_FETCH_MANY_SUCCESS, SECURITY_CUSTOMERS_FETCH_MANY_FAILURE,
  SECURITY_CUSTOMERS_SAVE_NOTE_REQUEST, SECURITY_CUSTOMERS_SAVE_NOTE_SUCCESS, SECURITY_CUSTOMERS_SAVE_NOTE_FAILURE,
  SECURITY_CUSTOMERS_UPLOAD_REQUEST, SECURITY_CUSTOMERS_UPLOAD_SUCCESS, SECURITY_CUSTOMERS_UPLOAD_FAILURE,
  SECURITY_CUSTOMERS_FETCH_REQUEST, SECURITY_CUSTOMERS_FETCH_SUCCESS, SECURITY_CUSTOMERS_FETCH_FAILURE,
  SECURITY_CUSTOMERS_SAVE_REQUEST, SECURITY_CUSTOMERS_SAVE_SUCCESS, SECURITY_CUSTOMERS_SAVE_FAILURE
} from './types'

export default {
  [SECURITY_CUSTOMERS_BATCH_DELETE_REQUEST] (state) {
    state.deleting = true
  },
  [SECURITY_CUSTOMERS_BATCH_DELETE_SUCCESS] (state) {
    state.deleting = false
  },
  [SECURITY_CUSTOMERS_BATCH_DELETE_FAILURE] (state) {
    state.deleting = false
  },
  [SECURITY_CUSTOMERS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [SECURITY_CUSTOMERS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
    state.validationErrors = {}
  },
  [SECURITY_CUSTOMERS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [SECURITY_CUSTOMERS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [SECURITY_CUSTOMERS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [SECURITY_CUSTOMERS_FETCH_FAILURE] (state) {
    state.loading = false
  },
  [SECURITY_CUSTOMERS_SAVE_REQUEST] (state) {
    state.data = []
    state.saving = true
  },
  [SECURITY_CUSTOMERS_SAVE_SUCCESS] (state) {
    state.saving = false
  },
  [SECURITY_CUSTOMERS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [SECURITY_CUSTOMERS_SAVE_NOTE_REQUEST] (state) {
    state.savingNote = true
  },
  [SECURITY_CUSTOMERS_SAVE_NOTE_SUCCESS] (state) {
    state.savingNote = false
  },
  [SECURITY_CUSTOMERS_SAVE_NOTE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.savingNote = false
  },
  [SECURITY_CUSTOMERS_UPLOAD_REQUEST] (state) {
    state.uploading = true
  },
  [SECURITY_CUSTOMERS_UPLOAD_SUCCESS] (state) {
    state.uploading = false
  },
  [SECURITY_CUSTOMERS_UPLOAD_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.uploading = false
  }
}
